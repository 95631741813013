import Vue from 'vue';
import { Datetime } from 'vue-datetime';
import { DateTime as LuxonDateTime } from 'luxon';
import { ApolloError } from 'apollo-boost';
import { VueTelInput } from 'vue-tel-input';
import gql from 'graphql-tag';

import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';
import Loader from '@/components/Shared/Loader/Loader';
import { MUTATION_KEYS } from '@/store';
import { sortAlphabetically } from '@/services/utils';

const initialFormData = {
  email: '',
  firstName: '',
  lastName: '',
  message: '',
  phone: '',
  selectedDate: '',
  selectedOffering: '',
  selectedTime: '',
};

const NewBooking = Vue.extend({
  data() {
    return {
      acceptTermsAndCondition: false,
      availableOfferings: [],
      availableSlotsForDate: [],
      currentBooking: null,
      currentBookingId: '',
      formData: { ...initialFormData },
      formError: '',
      formMessage: '',
      loading: false,
      loadingAvailableSlots: false,
      loadingOfferings: true,
      preselectedDay: '',
      selectedDate: '',
    };
  },

  methods: {
    currentOfferingDescription(selectedOffering: string) {
      if (!selectedOffering) return '';

      const offeringId = selectedOffering.split('|')[0].trim();

      const offering = (this.availableOfferings as any).find((i: any) => i.id === offeringId);

      return offering.description;
    },

    getAvailableBookingSlots(date: string) {
      this.loadingAvailableSlots = true;
      this.formData.selectedTime = '';

      const query = gql`
        query getAvailableBookingSlots($date: String) {
          getAvailableBookingSlots(date: $date){
            id
            time
          }
        }
      `;

      apollo.query({
        fetchPolicy: 'network-only',
        query,
        variables: {
          date,
        },
      })
        .then(({ data }) => {
          this.availableSlotsForDate = data?.getAvailableBookingSlots || [];
        })
        .catch((err) => {
          console.log('Error getting available slots => ', err);
        })
        .finally(() => {
          this.loadingAvailableSlots = false;
        });
    },

    getAvailableOfferings() {
      this.loadingOfferings = true;

      const query = gql`
        {
          getOfferings{
            enabled
            description
            id
            name
            price
          }
        }
      `;

      apollo.query({ query })
        .then(({ data }) => {
          const rawOfferings = data?.getOfferings || [];

          (this.availableOfferings as any) = sortAlphabetically(rawOfferings, 'name');
        })
        .catch((err) => {
          console.log('Error loading available services', err);
        })
        .finally(() => {
          this.loadingOfferings = false;
        });
    },

    handleAddNewBooking(event: Event) {
      event.preventDefault();

      this.formError = '';
      this.formMessage = '';

      const isAdmin = window.location.pathname.includes('admin');
      const { acceptTermsAndCondition, formData } = this;

      if (!isAdmin && !acceptTermsAndCondition) {
        this.formError = 'You have to accept the terms and condition';
        return;
      }

      this.loading = true;

      const createBookingQuery = gql`
        mutation createBooking(
          $email: String
          $firstName: String!
          $lastName: String!
          $message: String!
          $phone: String!
          $selectedDate: String!
          $selectedOffering: String!
          $selectedTime: String!
        ) {
          createBooking(
            email: $email
            firstName: $firstName
            lastName: $lastName
            message: $message
            phone: $phone
            selectedDate: $selectedDate
            selectedOffering: $selectedOffering
            selectedTime: $selectedTime
          ) {
            id
            selectedDay
          }
        }
      `;

      const updateBookingQuery = gql`
        mutation updateBooking(
          $confirmed: Boolean
          $email: String
          $firstName: String
          $id: String!
          $lastName: String
          $phone: String
          $selectedDate: String
          $selectedOffering: String
          $selectedTime: String
        ) {
          updateBooking(
            confirmed: $confirmed
            email: $email
            firstName: $firstName
            id: $id
            lastName: $lastName
            phone: $phone
            selectedDate: $selectedDate
            selectedOffering: $selectedOffering
            selectedTime: $selectedTime
          ) {
            ok
          }
        }
      `;

      const variables = this.currentBooking
        ? {
          ...formData,
          id: this.currentBookingId,
        }
        : formData;

      apollo.mutate({
        mutation: this.currentBooking ? updateBookingQuery : createBookingQuery,
        variables,
      })
        .then(({ data }) => {
          this.$emit('reloadBookingsList');

          // Reset form
          this.formData = { ...initialFormData };
          this.selectedDate = '';

          const response = this.currentBooking || data?.createBooking;


          if (isAdmin) {
            this.$router.push(`/admin/bookings/${response.id}?date=${response?.selectedDay}`);
            return;
          }

          this.formMessage = 'Booking successfully placed, you will receive an email when your booking is confirmed';
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');

          if (this.formError.includes('Unavailable:')) {
            this.formData.selectedDate = '';
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadEditData() {
      const currentBookingId = this.$route.params.id;

      if (!currentBookingId) {
        this.formData = { ...initialFormData };
        this.currentBooking = null;
        return;
      }

      this.currentBooking = this.$store.state.allBookings
        .find((booking: any) => booking.id === currentBookingId);
      this.currentBookingId = currentBookingId;

      if (!this.currentBooking) return;

      this.formData = {
        ...this.currentBooking as any,
      };
    },
  },

  mounted() {
    this.getAvailableOfferings();

    this.preselectedDay = (this.$route.query.date as string) || '';

    if (this.preselectedDay) {
      this.selectedDate = LuxonDateTime.fromISO(this.preselectedDay).toISO();
    }

    this.loadEditData();

    this.$store.subscribe((mutation) => {
      if (mutation.type === MUTATION_KEYS.updateAllBookings) {
        this.loadEditData();
      }
    });
  },

  name: 'new-booking',

  render() {
    const datepickerProps = {
      auto: true,
      'min-datetime': '',
    };

    // Funny type bug forces the props to be added twice
    const telProps = {
      defaultCountry: 'NG',
      disabled: true,
      mode: 'international',
      placeholder: 'Client phone number',
      required: true,
    };

    return (
      <div class="new-booking">
        <header class="dashboard-header">
          <h1 class="dashboard-header__title">New Booking</h1>
        </header>

        <form onSubmit={this.handleAddNewBooking} class="dashboard-content">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" name="firstName" v-model={this.formData.firstName} id="firstName" required />
          </div>

          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" name="lastName" v-model={this.formData.lastName} id="lastName" required />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model={this.formData.email} name="email" />
          </div>

          <div class="form-group">
            <label for="selectedOffering">Package</label>
            {
              this.loadingOfferings
                ? (
                  <div class="form-group">
                    <Loader small />
                  </div>
                )
                : (
                  <select name="selectedOffering" id="selectedOffering" v-model={this.formData.selectedOffering} required>
                    {
                      this.availableOfferings.map((slot: any) => (
                        <option value={`${slot.id} | ${slot.name}`}>{slot.name} - {slot.price.toLocaleString()}</option>))
                    }
                  </select>
                )
            }

            {
              this.formData.selectedOffering && (
                <p style={{ fontSize: '12px' }}>
                  Description: <em>
                    {this.currentOfferingDescription(this.formData.selectedOffering)}
                  </em>
                </p>
              )
            }
          </div>

          <div class="form-group">
            <label for="phone">Phone</label>
            <VueTelInput
              defaultCountry='NG'
              mode='international'
              placeholder='Phone number'
              required={true}
              v-model={this.formData.phone}
              {...telProps}
            />
          </div>

          <div class="form-group">
            <label for="date">Selected Date</label>

            <Datetime
              auto={true}
              min-datetime={LuxonDateTime.local().toISO()}
              v-model={this.selectedDate}
              {...datepickerProps}
            />
          </div>

          {
            this.loadingAvailableSlots
              ? (
                <div class="form-group">
                  <Loader small />
                </div>
              )
              : (

                <div class="form-group">
                  <label for="availableSlots">Available Slots For Date</label>

                  {
                    this.availableSlotsForDate.length > 0
                      ? (
                        <select name="availableSlots" id="availableSlots" v-model={this.formData.selectedTime} required>
                          {
                            this.availableSlotsForDate.map((slot: any) => (
                              <option value={slot.time}>{slot.time}</option>))
                          }
                        </select>
                      )
                      : <span class="generic-empty-state">
                        {
                          this.selectedDate
                            ? 'No booking slot available for selected date'
                            : 'Select a date to see the available slots for that date'
                        }
                      </span>
                  }
                </div>
              )
          }

          {
            <div class="form-group">
              <label for="message">Message (Optional)</label>
              <textarea name="message" id="" cols="30" rows="4" v-model={this.formData.message}></textarea>
            </div>
          }

          <div class="form-group checkbox terms" style={{ display: 'none' }}>
            <input type="checkbox" name="terms" id="terms" v-model={this.acceptTermsAndCondition} />
            <label for="terms">I accept the <router-link to="/terms" target="_blank">Terms &amp; Conditions</router-link></label>
          </div>

          {
            this.formError && (
              <div class="form-group">
                <div class="form-error">{this.formError}</div>
              </div>
            )
          }

          {
            this.formMessage && (
              <div class="form-group">
                <div class="form-success">{this.formMessage}</div>
              </div>
            )
          }

          <div class="form-group">
            <Button
              disabled={!this.formData.selectedTime}
              loading={this.loading}
              primary
              type='submit'
            >
              {this.currentBooking ? 'Update' : 'Create'} Booking
              </Button>
          </div>
        </form>
      </div>
    );
  },

  watch: {
    $route: 'loadEditData',

    selectedDate() {
      const { selectedDate } = this;

      this.formData.selectedDate = selectedDate;

      if (!selectedDate) return;

      const parsedDate = LuxonDateTime.fromISO(selectedDate).toISODate();

      this.getAvailableBookingSlots(parsedDate);
    },
  },
});

export default NewBooking;
